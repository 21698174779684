import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";


// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  lv: {
    translation: {
        "application_title": "Austiņas.lv",
        "slogan": `Laipni lūdzam mūsu internet veikalā! Mēs specializējamies uz austiņam.
        Mēs pardodam tikai augstas kvalitātes brendus! Tie nav daudzi!`
    }
  },
  ru: {
    translation: {
        "application_title": "Наушники.lv",
        "slogan": `Добро пожаловать в наш интернет магазин! Мы специализируемся на наушниках!
        Мы торгуем только брендами высокого качества! Их не много!`
    }
  },
  en: {
    translation: {
      "application_title": "Headphones.lv",
      "slogan": `Welcome to internet store. We are specializing on Headphones!
      We suggest only best high quality models! Not many, but good ones!`
    },
  }
};

// Added to be able to list all supported languages for language chooser
i18n.allLanguages = ["lv", "ru", "en"];
i18n.defaultLanguage = i18n.allLanguages[0];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    // lng: "lv", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    },
  });

if(i18n.language) {
  const lang = i18n.language.split('-')[0];
  if(i18n.allLanguages.lastIndexOf(lang) >= 0) {
    i18n.changeLanguage(lang)
  } else {
    i18n.changeLanguage(i18n.defaultLanguage)
  }
} else {
  i18n.changeLanguage(i18n.defaultLanguage);
}

export default i18n;